
import { Vue, Component } from 'vue-property-decorator'
import router from '@/router'
import { logger } from '@/utils/logger'
import auth from '@/services/auth'
import modules from '@/store/modules'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    AlertBox,
  },
  metaInfo() {
    return {
      title: modules.app.title,
      titleTemplate: `${this.$t('forgotPassword.TITLE')} - %s`,
    }
  },
})
export default class ForgotPassword extends Vue {
  valid = false
  errorMessage = false
  isSubmitting = false
  email = ''
  emailRules = [
    (v) => !!v || this.$t('forgotPassword.EMAIL_REQUIRED'),
    (v) => /.+@.+/.test(v) || this.$t('forgotPassword.EMAIL_MUST_BE_VALID'),
  ]
  success = false

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    const form = this.$refs.form as any
    try {
      const formIsValid = await form.validate()
      if (formIsValid) {
        this.isSubmitting = true
        await auth.forgotPassword({
          email: this.email,
        })
        this.success = true
        this.isSubmitting = false
        form.reset()
      }
    } catch (error) {
      this.isSubmitting = false
      logger.error(error)
    }
  }
}
